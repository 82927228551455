.App {
    background-color: #282c34;
    /* text-align: center; */
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 10vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    /* min-height: 100vh; */
    /* display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center; */
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-main {
    background-color: #282c34;
    /* min-height: 100vh; */
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* font-size: calc(10px + 2vmin); */
    color: white;
}

.App-link {
    color: #61dafb;
}

label {
    color: #282c34;
}

hr {
    height: 1px;
    border: 0;
    border-top: 1px solid #282c34;
    margin: 1em 0;
    padding: 0;
    border-color: #282c34;
    background-color: #282c34;
    color: #282c34;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@include media-breakpoint-up(sm) {
    html {
        font-size: 1.2rem;
    }
}

@include media-breakpoint-up(md) {
    html {
        font-size: 1.4rem;
    }
}

@include media-breakpoint-up(lg) {
    html {
        font-size: 1.6rem;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 300px) {
    html {
        font-size: 1rem;
    }
    #App-responsive {
        width: 400px;
        margin: auto;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 507px) {
    html {
        font-size: 1.2rem;
    }
    #App-responsive {
        width: 100%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    html {
        font-size: 1.4rem;
    }
    #App-responsive {
        width: 100%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    html {
        font-size: 1.6rem;
    }
    #App-responsive {
        width: 100%;
    }
}
